import React from "react"
import Usps from "../partials/usps";
import Result from "../partials/result";
import AprText from "../partials/apr-text";

class ApplicationResults extends React.Component {

    render() {

        return (
            <div className="section-container">
                <div className="section section-form">
                    <div className="container">
                        <div className="row">
                            <div className="col col-sm-4 col-md-3">
                                <div className="sidebar quote-form-sidebar">
                                    <div className="section-dark box box-blue box-my-loaned box-my-loaned-result">
                                        <h5><i className="fas fa-lock"></i>My Loaned</h5>
                                        <p>We have found lenders willing to lend
                                            you <strong>£2,000</strong> over <strong>12 months</strong>.</p>
                                    </div>
                                    <Usps slider={true}/>
                                    <AprText />
                                </div>
                            </div>
                            <div className="col col-sm-8 col-md-9 col-lg-8 col-lg-offset-1">
                                {this.props.progress < 100 ? (
                                    <div className="lenders-loading">
                                        <div className="section-form-title">
                                            <h4>Loading lenders who will provide your loan</h4>
                                            <p className="lead">This will only take a moment</p>
                                        </div>
                                        <div className="loading-icon">
                                            <span className="loading-icon-ring"></span>
                                            <span className="loading-icon-ring"></span>
                                            <span className="loading-icon-ring"></span>
                                            <span className="loading-icon-ring"></span>
                                            <div className="loading-icon-dot"></div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="lenders-loading">
                                        <div className="section-form-title">
                                            <h4>You've been pre-approved for the following loans.</h4>
                                            <p className="lead">Your best rate is 3.2%, based on £2,000 over 12
                                                months</p>
                                        </div>
                                    </div>
                                )}
                                <div className="lenders">
                                    {this.props.results.length > 0 && this.props.results.map(result => (
                                        <Result/>
                                    ))}
                                </div>

                                <h5>Guarantor loans</h5>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
                                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
                                    nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                    consequat.</p>

                                <div className="lenders">
                                    {this.props.results.length > 0 && this.props.results.map(result => (
                                        <Result/>
                                    ))}
                                </div>

                                <h5>Declined quote</h5>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
                                    incididunt ut labore et dolore magna aliqua.</p>

                                <div className="lenders">
                                    {this.props.results.length > 0 && this.props.results.map(result => (
                                        <div className="box box-border box-lender box-lender-declined">
                                            <div className="lender-logo">
                                                <div className="box box-lender-logo">

                                                </div>
                                            </div>
                                            <div className="lender-details">
                                                <p>An application for this loan amount &amp; term will be declined. They
                                                    may accept you for a different amount.</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ApplicationResults