import React from "react";
import Image from "../elements/image";

const Result = () => (
    <div className="box box-border box-lender">
        <div className="lender-logo">
            <div className="box box-lender-logo">
                <Image img={require('../../images/logo-sainsburys.png')} />
            </div>
            <p>
                <strong>Personal Loan</strong>
                Representative<br /> Example
            </p>
        </div>
        <div className="lender-details">
            <div className="lender-title">
                <div className="lender-title-inner">
                    <h6>Sainsbury's Bank</h6>
                    <p className="info-text"><i
                        className="far fa-info-circle"></i> 100% Pre-Approved</p>
                </div>
                <div className="lender-title-btn">
                    <a href=""
                       className="btn btn-sm btn-primary btn-loan-details">Details</a>
                    <a href="" className="btn btn-sm btn-default">Apply</a>
                </div>
            </div>
            <div className="loan-details">
                <div className="loan-detail">
                    3.2%
                    <span>Rate</span>
                </div>
                <div className="loan-detail">
                    £219
                    <span>Monthly</span>
                </div>
                <div className="loan-detail">
                    100%
                    <span>Certainty</span>
                </div>
                <div className="loan-detail">
                    £2,213.50
                    <span>Repayment</span>
                </div>
            </div>
            <div className="loan-full-details">
                <table>
                    <tbody>
                    <tr>
                        <th>Total amount repayable</th>
                        <td>£2,213.50</td>
                    </tr>
                    <tr>
                        <th>Annual interest rate</th>
                        <td>4.1%</td>
                    </tr>
                    <tr>
                        <th>Pre-approved certainty</th>
                        <td>100%</td>
                    </tr>
                    <tr>
                        <th>Represented APR</th>
                        <td>4.1%</td>
                    </tr>
                    <tr>
                        <th>Guaranteed for</th>
                        <td>14d 8h</td>
                    </tr>
                    <tr>
                        <th>Funds transferred</th>
                        <td>Same Day</td>
                    </tr>
                    <tr>
                        <th>Fees</th>
                        <td>£50</td>
                    </tr>
                    </tbody>
                </table>
                <a href="" className="btn-loan-details">Hide Details <i
                    className="far fa-angle-up icon-right"></i></a>
            </div>
        </div>
    </div>

)

export default Result