import React from "react"
import {Link, navigate} from "gatsby"

import Layout from "../layouts/index"
import SEO from "../components/seo"
import {Redirect, Router} from "@reach/router"
import ApplicationResults from "../components/section/application-results";

class Results extends React.Component {

    constructor(props){
        super(props)
        this.state = {progress: 0, results: {}}
    }

    componentDidMount() {
        let lead = this.getLead()
        if(!lead){
            return
        }
        this.fetchResults(lead)
    }

    fetchResults(lead) {
        fetch("http://loanedapi.clientstage.co.uk/lender/lead/progress/" + lead, {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        }).then(response => {
            return response.json()
        }).then(response => {
            this.setState({
                progress: response.progress,
                results: response.results,
            })
            if(response.progress < 100) {
                const self = this
                window.setTimeout(function(){
                    self.fetchResults(lead)
                }, 500)
            }
        })
    }

    getLead() {
        const location = this.props.location.state
        if (location && location.lead) {
            return location.lead
        }
    }

    render() {
        const lead = this.getLead()
        if (!lead) {
            return <Redirect to="/search/" noThrow/>
        }
        return (
            <Layout location={this.props.location}>
                <SEO title="Results" bodyAttributes={{class: 'quote-page'}}/>
                <ApplicationResults progress={this.state.progress} results={this.state.results} />
            </Layout>
        )
    }
}

export default Results